$ ->
  #==============================
  # ビューモデル
  #==============================
  #***** ログイン前画面ヘッダー共通VM *****
  window.BlueHeader = (data)->
    #------------------------------
    # プロパティ
    #------------------------------
    @typeCode = ko.observable window.NEO.typeCode
    @appDir = ko.observable data?.appDir
    @areaCode = ko.observable "blue_body"
    @albumName = ko.observable()
    @schoolName = ko.observable()
    @periodEndAt = ko.observable()
    @photoStudioName = ko.observable()
    @userFullName = ko.observable()

    #------------------------------
    # イベント
    #------------------------------
    @openHelp = ()=>
      window.open(window.NEO.url.page_help_signup, '_blank')
      @
    @moveToLogout = ()=>
      location.href=window.NEO.url.page_logout
      @
    @


